import React from 'react';
import { withTranslation } from 'react-i18next';
import ArrowButton from '../component/ArrowButton'
import international_truck_driver from '../media/international_truck_driver.jpg';
import './HomeProject.css';


class HomeProject extends React.Component {
    render() {
        return <div className="App-home-project">
            <div className="App-home-project-subtitle">
                {this.props.t('HomeProject.SubTitle')}
            </div>
            <div className="App-home-project-driver-image-container">
                <img className="international-driver" src={international_truck_driver} alt="international truck driver" />
            </div>
            <div className="App-home-project-content" dangerouslySetInnerHTML={{ __html: this.props.t('HomeProject.Content')}}>
            </div>
            <div className="App-home-project-btn-container" style={{textAlign: 'right'}}>
                <ArrowButton className="ArrowButton-more" text={this.props.t('Common.More')} navigateto="/project" />
            </div>
        </div>
    }
}

export default withTranslation()(HomeProject);