import React from 'react';
import PropTypes from 'prop-types';
import {v4 as uuidv4} from 'uuid';
import './ToggleSwitch.css';

export default class ToggleSwitch extends React.Component {

    constructor(props) {
        super(props);
        this.state = { checked: false };
        if (this.props.checked === true || this.props.checked === 'true' || this.props.checked === 'True') {
            this.state.checked = true;
        }
        this.getChecked = this.getChecked.bind(this);
        this.setChecked = this.setChecked.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.handleValue1Click = this.handleValue1Click.bind(this);
        this.handleValue2Click = this.handleValue2Click.bind(this);
    }

    getChecked() {
        return this.state.checked;
    }

    setChecked(value) {
        this.setState({ checked: value });
        let onCheck = this.props.onCheck;
        if (onCheck && typeof onCheck === 'function') {
            onCheck(value);
        }
    }

    handleCheck() {
        let checked = this.getChecked();
        this.setChecked(!checked);
    }

    handleValue1Click() {
        let checked = this.getChecked();
        if (checked === true) {
            this.setChecked(false);
        }
    }

    handleValue2Click() {
        let checked = this.getChecked();
        if (checked === false) {
            this.setChecked(true);
        }
    }

    render() {
        let toggleSwitchId = null;
        if (this.props.id && this.props.id.length > 0) {
            toggleSwitchId = this.props.id;
        } else {
            toggleSwitchId = 'tsi-' + uuidv4();
        }
        let extraClassName = '';
        if (this.props.className && this.props.className.length > 0) {
            extraClassName = ' ' + this.props.className;
        }
        let value1 = '';
        if (this.props.value1 && this.props.value1.length > 0) {
            value1 = this.props.value1;
        }
        let value2 = '';
        if (this.props.value2 && this.props.value2.length > 0) {
            value2 = this.props.value2;
        }
        return (
            <div id={toggleSwitchId} className={`ToggleSwitch${extraClassName}`}>
                <span className={`ToggleSwitch-value ToggleSwitch-value-1 eurostile-condensed${this.state.checked ? '' : ' checked'}`} onClick={this.handleValue1Click}>{value1}</span>
                <div className="ToggleSwitch-inner">
                    <input className="ToggleSwitch-input" type="checkbox" onChange={this.handleCheck} checked={this.state.checked} />
                    <label className="ToggleSwitch-label" htmlFor={toggleSwitchId}></label>
                </div>
                <span className={`ToggleSwitch-value ToggleSwitch-value-2 eurostile-condensed${this.state.checked ? ' checked' : ''}`} onClick={this.handleValue2Click}>{value2}</span>
            </div>
        );
    }
};

ToggleSwitch.defaultProps = {
    checked: false,
    value1: 'Value 1',
    value2: 'Value 2',
    onCheck: undefined
};

ToggleSwitch.propTypes = {
    checked: PropTypes.bool,
    value1: PropTypes.string,
    value2: PropTypes.string,
    onCheck: PropTypes.func
};