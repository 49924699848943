import React from 'react';
import PropTypes from 'prop-types';
import './Slogan.css';

export default class Slogan extends React.Component {

    constructor(props) {
        super(props);
        if (this.props.hasAnimation === true || this.props.hasAnimation === 'true' || this.props.hasAnimation === 'True') {
            this.player = React.createRef();
        }
    }

    onAnimationEvent(event) {
        var currentPlayer = this.player.current;
        if (event === 'load') {
            setTimeout(function() {
                currentPlayer.play();
            }, 800);
        } else if (event === 'complete') {
            setTimeout(function() {
                currentPlayer.stop();
                currentPlayer.play();
            }, 60000);
        }
    }

    render() {
        let extraClassName = '';
        if (this.props.className && this.props.className.length > 0) {
            extraClassName = ' ' + this.props.className;
        }
        let animation;
        // Enable if needed in future
        // if (this.props.hasAnimation === true || this.props.hasAnimation === 'true' || this.props.hasAnimation === 'True') {
        //     extraClassName = ' Slogan-animation' + extraClassName;
        //     animation = (
        //         <div className="Slogan-inline-animation">
        //             <Player ref={this.player}
        //                     autoplay={false}
        //                     keepLastFrame={true}
        //                     src={lottie_binaercode}
        //                     onEvent={event => { this.onAnimationEvent(event); }} />
        //         </div>
        //     );
        // }
        return (
            <div className={`Slogan${extraClassName}`}>
                <div className="Slogan-inline">
                    <span className="eurostile-condensed-heavy">road safety</span>
                    <span className="eurostile-condensed">
                        <br className="Slogan-inline-br-1" />
                        <span className="Slogan-inline-span-1">is our passion</span>
                    </span>
                </div>
                {animation}
            </div>
        );
    }
}

Slogan.defaultProps = {
    hasAnimation: false
};

Slogan.propTypes = {
    hasAnimation: PropTypes.bool
}