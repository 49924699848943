import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { scrollTo, getLocationHashId } from '../App'
import i18next from 'i18next';
import BookishContainer from '../component/BookishContainer'
import Slogan from '../component/Slogan'
import Footer from '../component/Footer'
import drivers_world_wide from '../media/map_indien_indonesia_philippinen.jpg';
import international_truck_driver from '../media/international_truck_driver.jpg';
import flag_austria from '../media/flag_austria.jpg'
import flag_india from '../media/flag_india.jpg'
import './Project.css';

class Project extends React.Component {
    
    componentDidMount() {
        if (scrollTo(this.props.scrollto) === false) {
            if (scrollTo(getLocationHashId()) === false) {
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            }
        }
    }

    render() {
        return (
            <div className="App-project">
                {/* Slogan */}
                <BookishContainer className="App-slogan-container">
                    <Slogan className="App-slogan" />
                </BookishContainer>
                {/* Intro */}
                <BookishContainer id="intro" header={this.props.t('Common.Project')} backgroundColor="#95A6B1" topLeftBorderBackgroundColor="#FFFFFF">
                    <IntroSection />
                </BookishContainer>
                {/* LackOfDrivers */}
                <BookishContainer id="lackOfDrivers" backgroundColor="#D5DBE0" >
                    <LackOfDriversSection />
                </BookishContainer>
                {/* MoreDriversSection */}
                <BookishContainer id="moreDrivers" backgroundColor="#EBECF0" >
                    <MoreDriversSection />
                </BookishContainer>
                {/* InternationalExpertiseSection */}
                <BookishContainer id="inernationalExpertise" backgroundColor="#F5F6F7" >
                    <InternationalExpertiseSection />
                </BookishContainer>
                {/* Contact */}
                <BookishContainer id="contact" textColor="#FFFFFF" backgroundColor="#18375E" topLeftBorderBackgroundColor="#F5F6F7">
                    <Footer hasDownsize={true} />
                </BookishContainer>
            </div>
        );
    }
}

export default withTranslation()(Project);

Project.defaultProps = {
    scrollto: ''
};

Project.propTypes = {
    scrollto: PropTypes.string
}

function TextSection(title, content) {
    return (
        <div className="TextSection-container">
            <div className="TextSection-title">{title}</div>
            <div className="TextSection-content">{content}</div>
        </div>
    );
}

function IntroSection() {
    return (
        <div className="App-project-intro">
             <div className="App-project-subtitle">
                {i18next.t('HomeProject.SubTitle')}
            </div>
            <div className="App-project-image-container">
                <img className="project-image" src={international_truck_driver} alt="international truck driver" />
            </div>
            <div className="App-project-intro-details section-end">
                {TextSection(i18next.t('Project.Intro.Title'), i18next.t('Project.Intro.Content'))}
            </div>
        </div>
    );
}

function LackOfDriversSection() {
    return (
        <div className="App-project-lack-of-drivers section-start">
            <div className="App-project-lack-of-drivers-details">
                {TextSection(i18next.t('Project.LackOfDrivers.Part1.Title'), <span dangerouslySetInnerHTML={{ __html: i18next.t('Project.LackOfDrivers.Part1.Content')}}></span>)}
            </div>
            <div className="App-project-image-container">
                <img className="project-image" src={drivers_world_wide} alt="international drivers world wide" />
            </div>
            <div className="App-project-lack-of-drivers-details section-end">
                {TextSection('', i18next.t('Project.LackOfDrivers.Part2.Content'))}
            </div>
        </div>
    );
}

function MoreDriversSection() {
    return(
        <div className="App-project-more-drivers section-start">
            <div className="App-project-more-drivers-details">
                {TextSection(i18next.t('Project.MoreDrivers.Title'), i18next.t('Project.MoreDrivers.Content'))}
            </div>
            <div className="App-project-more-drivers-locations">
                <BookishContainer id="heindia" header={i18next.t('Project.MoreDrivers.HEIndia.Title')} headerImages={[flag_india]} textColor="#FFFFFF" backgroundColor="#18375E" topLeftBorderBackgroundColor="#EBECF0" topRightBorderBackgroundColor="#EBECF0" bottomLeftBorderBackgroundColor="#EBECF0" bottomRightBorderBackgroundColor="#EBECF0">
                    <div className="bookish-content" dangerouslySetInnerHTML={{ __html: i18next.t('Project.MoreDrivers.HEIndia.Content')}} />
                </BookishContainer>
                <BookishContainer id="council" header={i18next.t('Project.MoreDrivers.Council.Title')} headerImages={[flag_india, flag_austria]} textColor="#FFFFFF" backgroundColor="#18375E" topLeftBorderBackgroundColor="#EBECF0" topRightBorderBackgroundColor="#EBECF0" bottomLeftBorderBackgroundColor="#EBECF0" bottomRightBorderBackgroundColor="#EBECF0">
                    <div className="bookish-content" dangerouslySetInnerHTML={{ __html: i18next.t('Project.MoreDrivers.Council.Content')}} />
                </BookishContainer>
                <BookishContainer id="heaustria" header={i18next.t('Project.MoreDrivers.HEAustria.Title')} headerImages={[flag_austria]} textColor="#FFFFFF" backgroundColor="#18375E" topLeftBorderBackgroundColor="#EBECF0" topRightBorderBackgroundColor="#EBECF0" bottomLeftBorderBackgroundColor="#EBECF0" bottomRightBorderBackgroundColor="#EBECF0">
                    <div className="bookish-content" dangerouslySetInnerHTML={{ __html: i18next.t('Project.MoreDrivers.HEAustria.Content')}} />
                </BookishContainer>
            </div>
        </div>
    );
}

function InternationalExpertiseSection() {
    return (
        <div className="App-project-international-expertise">
             <div className="App-project-international-expertise-details section-start">
                {TextSection(i18next.t('Project.InternationalExpertise.Title'), <span dangerouslySetInnerHTML={{ __html: i18next.t('Project.InternationalExpertise.Content')}}></span>)}
            </div>
            <div className="App-project-video-container">
                <iframe className="project-video" title="Corporate Film" src="https://www.youtube.com/embed/D03Q5gIiXVY?autoplay=0&amp;enablejsapi=1&amp;wmode=opaque" allowFullScreen id="player_1" name="fitvid0" style={{opacity: 1}}></iframe>
            </div>
            <div className="App-project-international-expertise-eu-license-classes">
                {TextSection(i18next.t('Project.InternationalExpertise.EULicenseClasses.Title'), <span dangerouslySetInnerHTML={{ __html: i18next.t('Project.InternationalExpertise.EULicenseClasses.Content')}}></span>)}
            </div>
            <div className="App-project-international-expertise-contact-ous section-start section-end">
                {TextSection(i18next.t('Project.InternationalExpertise.ContactOus.Title'), i18next.t('Project.InternationalExpertise.ContactOus.Content'))}
            </div>
        </div>
    );
}