import React from 'react';
import { withTranslation } from 'react-i18next';
import './HomeCompanyHistory.css';

class HomeCompanyHistory extends React.Component {
    render() {
        return (
            <div className="App-home-company-history">
                <div className='App-home-company-history-header'>
                    <p>{this.props.t('HomeCompanyHistory.Header')}</p>
                </div>
                <div className="App-home-company-history-entry">
                    <div className="App-home-company-history-entry-left noimage">
                        <span className="eurostile-condensed-heavy">1975</span>
                    </div>
                    <div className="App-home-company-history-entry-right">
                        <div className="App-home-company-history-entry-right-header eurostile-condensed-heavy">
                            <span>{this.props.t('HomeCompanyHistory.Part1.Title')}</span>
                        </div>
                        <div className="App-home-company-history-entry-right-text">
                            <p>{this.props.t('HomeCompanyHistory.Part1.Content')}</p>
                        </div>
                    </div>
                </div>
                <div className="App-home-company-history-entry">
                    <div className="App-home-company-history-entry-left noimage">
                        <span className="eurostile-condensed-heavy">1996</span>
                    </div>
                    <div className="App-home-company-history-entry-right">
                        <div className="App-home-company-history-entry-right-header eurostile-condensed-heavy">
                            <span>{this.props.t('HomeCompanyHistory.Part2.Title')}</span>
                        </div>
                        <div className="App-home-company-history-entry-right-text">
                            <p>{this.props.t('HomeCompanyHistory.Part2.Content')}</p>
                        </div>
                    </div>
                </div>
                <div className="App-home-company-history-entry">
                    <div className="App-home-company-history-entry-left noimage">
                        <span className="eurostile-condensed-heavy" dangerouslySetInnerHTML={{ __html: this.props.t('HomeCompanyHistory.Part3.Date')}}></span>
                    </div>
                    <div className="App-home-company-history-entry-right">
                        <div className="App-home-company-history-entry-right-header eurostile-condensed-heavy">
                            <span>{this.props.t('HomeCompanyHistory.Part3.Title')}</span>
                        </div>
                        <div className="App-home-company-history-entry-right-text">
                            <p dangerouslySetInnerHTML={{ __html: this.props.t('HomeCompanyHistory.Part3.Content')}}></p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(HomeCompanyHistory);