import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import './BorderButton.css';

class BorderButton extends React.Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        if (this.props.navigateto && this.props.navigateto.length > 0) {
            this.props.navigate(this.props.navigateto);
        }
        if (this.props.onClick && typeof this.props.onClick === 'function') {
            this.props.onClick();
        }
    }

    render() {
        let extraClassName = '';
        if (this.props.className && this.props.className.length > 0) {
            extraClassName = ' ' + this.props.className;
        }
        return (
            <div className={`BorderButton${extraClassName}`} onClick={this.handleClick}>
                <span className="eurostile-condensed-heavy">{this.props.text}</span>
            </div>
        );
    }
}

BorderButton.defaultProps = {
    text: '',
    navigateto: ''
};

BorderButton.propTypes = {
    text: PropTypes.string,
    navigateto: PropTypes.string
}

export default function BorderButtonUseNavigate(props) {
    const navigate = useNavigate();
    return <BorderButton {...props} navigate={navigate} />
}