import React from 'react';
import PropTypes from 'prop-types';
import { scrollTo, getLocationHashId } from '../App'
import { withTranslation } from 'react-i18next';
import BookishContainer from '../component/BookishContainer'
import Footer from '../component/Footer'
import HomeIntro from './HomeIntro'
import HomeIntroDescription from './HomeIntroDescription'
import HomeServices from './HomeServices'
import HomeReferences from './HomeReferences'
import HomeCompanyHistory from './HomeCompanyHistory'
import HomeProject from './HomeProject';
import HomeHEIndia from './HomeHEIndia';
import './Home.css';

class Home extends React.Component {

    componentDidMount() {
        if (scrollTo(this.props.scrollto) === false) {
            if (scrollTo(getLocationHashId()) === false) {
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            }
        }
    }

    componentDidUpdate() {
        scrollTo(this.props.scrollto);
    }

    render() {
        return (
            <div className="App-home">
                {/* Intro */}
                <BookishContainer id="intro" backgroundColor="#FFFFFF" bottomRightBorderBackgroundColor="#FFFFFF">
                    <HomeIntro />
                </BookishContainer>
                {/* IntroText */}
                <BookishContainer id="introtext" textColor="#FFFFFF" backgroundColor="#18375E" topLeftBorderBackgroundColor="#FFFFFF" bottomRightBorderBackgroundColor="#95A6B1">
                    <HomeIntroDescription />
                </BookishContainer>
                {/* Services */}
                <BookishContainer id="services" header={this.props.t('HomeService.Services')} backgroundColor="#95A6B1" topLeftBorderBackgroundColor="#18375E" bottomRightBorderBackgroundColor="#B5C1C9">
                    <HomeServices />
                </BookishContainer>
                {/* Project */}
                <BookishContainer id="project" header={this.props.t('Common.Project')} backgroundColor="#B5C1C9" topLeftBorderBackgroundColor="#95A6B1" bottomRightBorderBackgroundColor="#18375E">
                    <HomeProject />
                </BookishContainer>
                {/* HEIndia */}
                <BookishContainer id="heindia" header={this.props.t('Common.HEIndia')} backgroundColor="#18375E" textColor="#FFFFFF" topLeftBorderBackgroundColor="#B5C1C9" bottomRightBorderBackgroundColor="#D5DBE0">
                    <HomeHEIndia />
                </BookishContainer>
                {/* References */}
                <BookishContainer id="references" header={this.props.t('Common.Customers')} backgroundColor="#D5DBE0" topLeftBorderBackgroundColor="#18375E" bottomRightBorderBackgroundColor="#18375E">
                    <HomeReferences />
                </BookishContainer>
                {/* 01/2024: Hide company history */}
                {/* Company History */}
                {/* <BookishContainer id="companyhistory" header={this.props.t('Common.CompanyHistory')} backgroundColor="#EBECF0" topLeftBorderBackgroundColor="#D5DBE0" bottomRightBorderBackgroundColor="#18375E">
                    <HomeCompanyHistory />
                </BookishContainer> */}
                {/* Contact */}
                <BookishContainer id="contact" header={this.props.t('Common.Contact')} textColor="#FFFFFF" backgroundColor="#18375E" topLeftBorderBackgroundColor="#D5DBE0">
                    <Footer hasGoogleMaps={true} />
                </BookishContainer>
            </div>
        );
    }
}

export default withTranslation()(Home);

Home.defaultProps = {
    scrollto: ''
};

Home.propTypes = {
    scrollto: PropTypes.string
}