import React from 'react';
import PropTypes from 'prop-types';
import './BookishContainer.css';

export default class BookishContainer extends React.Component {
    render() {
        const borderRadius = '30px';
        const bookishContainerStyle = {
            color: this.props.textColor,
            backgroundColor: this.props.backgroundColor
        };
        let bookishContainerId = null;
        if (this.props.id && this.props.id.length > 0) {
            bookishContainerId = this.props.id;
        }
        let extraClassName = '';
        if (this.props.className && this.props.className.length > 0) {
            extraClassName = ' ' + this.props.className;
        }
        if (this.props.topLeftBorderBackgroundColor !== 'transparent') {
            bookishContainerStyle.borderTopLeftRadius = borderRadius;
        }
        if (this.props.topRightBorderBackgroundColor !== 'transparent') {
            bookishContainerStyle.borderTopRightRadius = borderRadius;
        }
        if (this.props.bottomLeftBorderBackgroundColor !== 'transparent') {
            bookishContainerStyle.borderBottomLeftRadius = borderRadius;
        }
        if (this.props.bottomRightBorderBackgroundColor !== 'transparent') {
            bookishContainerStyle.borderBottomRightRadius = borderRadius;
        }
        let headerImages;
        if (this.props.headerImages && this.props.headerImages.length > 0) {
            let images = [];
            for (let idx = 0; idx < this.props.headerImages.length; idx++)
            {
                images.push(
                    <span key={`bookish-image-item-${idx}`}>
                        <img src={this.props.headerImages[idx]} alt='' />
                    </span>
                );
            }
            headerImages = (
                 <div className="BookishContainer-header-images">
                    {images}
                </div>
            );
        }
        let headerDiv;
        if (this.props.header && this.props.header.length > 0) {
            let headerDesktopSpan = null;
            if (this.props.headerDesktop && this.props.headerDesktop.length > 0) {
                headerDesktopSpan = (
                    <span className="eurostile-condensed-heavy desktop">{this.props.headerDesktop}</span>
                );
            }
            headerDiv = (
                <div className={`BookishContainer-header${headerDesktopSpan !== null ? ' has-desktop' : ''}`}>
                    <span className="eurostile-condensed-heavy">{this.props.header}</span>
                    {headerDesktopSpan}
                </div>
            );
        }
        let subheaderDiv;
        if (this.props.subheader && this.props.subheader.length > 0) {
            let subheaderDesktopSpan = null;
            if (this.props.subheaderDesktop && this.props.subheaderDesktop.length > 0) {
                subheaderDesktopSpan = (
                    <span className="eurostile-condensed-heavy desktop">{this.props.subheaderDesktop}</span>
                );
            }
            subheaderDiv = (
                <div className={`BookishContainer-subheader${subheaderDesktopSpan !== null ? ' has-desktop' : ''}`}>
                    <span className="eurostile-condensed-heavy">{this.props.subheader}</span>
                    {subheaderDesktopSpan}
                </div>
            );
        }
        return (
            <div id={bookishContainerId} className={`BookishContainer${extraClassName}`}>
                <div className="BookishContainer-top-left" style={{backgroundColor: this.props.topLeftBorderBackgroundColor}}></div>
                <div className="BookishContainer-top-right" style={{backgroundColor: this.props.topRightBorderBackgroundColor}}></div>
                <div className="BookishContainer-bottom-left" style={{backgroundColor: this.props.bottomLeftBorderBackgroundColor}}></div>
                <div className="BookishContainer-bottom-right" style={{backgroundColor: this.props.bottomRightBorderBackgroundColor}}></div>
                <div className="BookishContainer-inner" style={bookishContainerStyle}>
                    {headerImages}
                    {headerDiv}
                    {subheaderDiv}
                    {this.props.children}
                </div>
            </div>
        );
    }
}

BookishContainer.defaultProps = {
    header: '',
    headerImages: [],
    headerDesktop: '',
    subheader: '',
    subheaderDesktop: '',
    textColor: '#000000',
    backgroundColor: '#ffffff',
    topLeftBorderBackgroundColor: 'transparent',
    topRightBorderBackgroundColor: 'transparent',
    bottomLeftBorderBackgroundColor: 'transparent',
    bottomRightBorderBackgroundColor: 'transparent'
};

BookishContainer.propTypes = {
    header: PropTypes.string,
    headerImages: PropTypes.array,
    headerDesktop: PropTypes.string,
    subheader: PropTypes.string,
    subheaderDesktop: PropTypes.string,
    textColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    topLeftBorderBackgroundColor: PropTypes.string,
    topRightBorderBackgroundColor: PropTypes.string,
    bottomLeftBorderBackgroundColor: PropTypes.string,
    bottomRightBorderBackgroundColor: PropTypes.string
}