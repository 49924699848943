import React from 'react';
import { withTranslation } from 'react-i18next';
import meeting from '../media/besprechung.jpg';
import './HomeHEIndia.css';
import BorderButton from '../component/BorderButton'

function heindiaClick(event) {
  window.open("https://www.he-india.com", "_blank").focus();
}

class HomeHEIndia extends React.Component {
    render() {
        return (
          <div className="App-home-heindia">
            <div className="App-home-heindia-content-1" dangerouslySetInnerHTML={{ __html: this.props.t('HomeHEIndia.Content.First')}}>
            </div>
            <div className="App-home-heindia-image-container">
              <img className="App-home-heindia-image-container-meeting" src={meeting} alt='meeting' />
            </div>
            <div className="App-home-heindia-content-2" dangerouslySetInnerHTML={{ __html: this.props.t('HomeHEIndia.Content.Second')}}>
            </div>
            <div className="App-home-heindia-actions">
              <BorderButton className="BorderButton-heindia" text="www.he-india.com" onClick={heindiaClick} />
            </div>
          </div>
        );
    }
}

export default withTranslation()(HomeHEIndia);