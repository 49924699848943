import React from 'react';
import { withTranslation } from 'react-i18next';
import austria_india from '../media/austria_india.jpg'
import './HomeIntroDescription.css';

class HomeIntroDescription extends React.Component {
    render() {
        return <div className="App-home-intro-description">
            <div className="App-home-intro-description-general">
                {this.props.t('Common.IntroDescription.General')}
            </div>
            <div className="App-home-intro-description-image-container">
                <img className="App-home-intro-description-image" src={austria_india} alt='austria india' />
            </div>
            <div className="App-home-intro-description-detailed">
                {this.props.t('Common.IntroDescription.Detailed')}
            </div>
        </div>;
    };
}

export default withTranslation()(HomeIntroDescription);