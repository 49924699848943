import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18nextConf';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>,
);

const VLE = {

  isScreenWidth500: false,
	resizeLock: undefined,

	getScreenWidth: function () {
		if (window.screen && window.screen.width > 0) {
			return window.screen.width;
		}
    return 0;
	},

	getScreenHeight: function () {
		if (window.screen && window.screen.height > 0) {
			return window.screen.height;
		}
    return 0;
	},

  onResize: function() {
    if (typeof VLE.resizeLock !== 'undefined' && VLE.resizeLock >= 0) {
      VLE.resizeLock++;
      return;
    }
    VLE.resizeLock = 0;
		let screenWidth = VLE.getScreenWidth();
    if (VLE.isScreenWidth500 === true && screenWidth >= 500) {
      VLE.isScreenWidth500 = false;
      let mvp = document.getElementById('viewport');
      mvp.setAttribute('content','width=device-width, initial-scale=1.0, user-scalable=0');
    }
    else if (VLE.isScreenWidth500 === false && screenWidth > 0 && screenWidth < 500) {
      VLE.isScreenWidth500 = true;
      let mvp = document.getElementById('viewport');
      mvp.setAttribute('content','width=500, user-scalable=0');
    }
    setTimeout(function () {
      if (typeof VLE.resizeLock !== 'undefined' && VLE.resizeLock > 0) {
        VLE.resizeLock = undefined;
        VLE.onResize();
      } else {
        VLE.resizeLock = undefined;
      }
    }, 1000);
	},

  hexToRgb: function (hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    var rgb = { r: parseInt(result[1], 16), g: parseInt(result[2], 16), b: parseInt(result[3], 16) };
    var rgb255 = { r: rgb.r/255, g: rgb.g/255, b: rgb.b/255 };
    return {
      hex: hex,
      rgb: "rgb("+rgb.r+", "+rgb.g+", "+rgb.b+")",
      rgb255: "["+rgb255.r+","+rgb255.g+","+rgb255.b+",1]"
    };
  },

  buildHyperlink: function (path) {
		let origin = '';
    if (document.location && document.location.origin && document.location.origin.length > 0) {
      origin = document.location.origin;
    }
    if (path && path.length > 0) {
      origin = origin + path;
    }
    return origin;
	},

  run: function () {
    window.onresize = VLE.onResize;
    VLE.onResize();
  }
}
document.VLE = VLE;
VLE.run();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
