import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import trans_de from "./translations/de.json";
import trans_en from "./translations/en.json";

const fallbackLng = ['de'];
const resources = {
    en: {
        translation: trans_en
    },
    de: {
        translation: trans_de
    }
}
const options = {
    order: ['querystring', 'navigator'],
    lookupQuerystring: 'lng'
}

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng,
        debug: false,
        detection: options,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;