import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import arrow_000000 from '../media/arrow_right_000000.svg';
import arrow_f7a823 from '../media/arrow_right_f7a823.svg';
import './ArrowButton.css';

class ArrowButton extends React.Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        if (this.props.navigateto && this.props.navigateto.length > 0) {
            this.props.navigate(this.props.navigateto);
        }
        if (this.props.onClick && typeof this.props.onClick === 'function') {
            this.props.onClick();
        }
    }

    render() {
        let extraClassName = '';
        if (this.props.className && this.props.className.length > 0) {
            extraClassName = ' ' + this.props.className;
        }
        return (
            <div className={`ArrowButton${extraClassName}`} onClick={this.handleClick}>
                <div className="text">
                    <span className="eurostile-condensed-heavy">{this.props.text}</span>
                </div>
                <div className="arrow">
                    <img src={arrow_000000} className="normal" alt="arrow-right" />
                    <img src={arrow_f7a823} className="hover" alt="arrow-right-hover" />
                </div>
            </div>
        );
    }
}

ArrowButton.defaultProps = {
    text: '',
    navigateto: ''
};

ArrowButton.propTypes = {
    text: PropTypes.string,
    navigateto: PropTypes.string
}

export default function ArrowButtonUseNavigate(props) {
    const navigate = useNavigate();
    return <ArrowButton {...props} navigate={navigate} />
}